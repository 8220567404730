import React from 'react';
/*import News from './NewsAPI';*/
import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div class="front-photoshart-main">
        <h1 class="front-photoshart-main-h1">Photoshart</h1>
        <p>This is a shart of a paragraph and you should be ashamed of even visiting this page.</p>
        <p>It's coming, but like many good hefty things, you just have to wait for it.</p>
        <p>Click the poo.</p>
        <img class="img_poo_click" src=".\poo_emoji.png" alt="Photoshart"></img>
      </div>
  );
}

export default App;
